'use strict';

$(function () {

    // Stats
    // -----
    _paq.push(['enableHeartBeatTimer']);

    // Settings
    // --------
    var mobileBreakPixels = 567;
    var themeRoot = $('[data-clearface-info]').data('root');

    // Variables
    // =========
    var currentUrl = window.location.pathname;

    // var mainPageUrl = ;
    // var popoverUrl = "";

    // Inital Setup
    // ============

    var isPopover = $('[data-clearface-info]').data('type') === 'popover';
    if (isPopover) {
        // Load homepage into the background
        $.get("/").done(function (responseBody) {
            // Set the title and track the page
            var $incoming = $('[data-clearface-info]', responseBody);
            bindLinks($incoming.children());
            addPageContentMain($incoming, true);
        }).fail(function () {
            console.log(arguments);
            console.log('failed to load');
        }).always(function () {
            setIsLoading(false);
        });
    }
    addPageContent($('[data-clearface-info]'));

    // Bind clicks to goTo function
    // ----------------------------

    bindLinks($("#clearface"));

    // Play Videos
    // -----------
    $('video[autoplay]').each(function (idx, el) {
        return el.play();
    });

    function bindLinks($el) {
        $el.on("click", "a, area", function (e) {
            var href = $(this).attr("href");
            if (e.ctrlKey || e.altKey || e.shiftKey) {
                return;
            }
            return goTo(href);
        });
    }

    function isAjaxLink(url) {
        if (W.startsWith(url, '#')) {
            return false;
        }
        var parsedURL = parseURL(url);

        if (parsedURL.hostname !== window.location.hostname) {
            return false;
        }

        return true;
    }

    function isCurrentPage(url) {
        var parsedURL = parseURL(url);
        if (parsedURL.pathname == currentUrl) {
            return true;
        }
        return false;
    }

    function goTo(url, isPopStateEvent) {

        if (!isAjaxLink(url)) {
            return true;
        }

        if (!isPopStateEvent) {
            history.pushState({}, '', url);
        }

        updateNavItemsActiveState(parseURL(url).pathname);

        load(url);
        return false;
    }

    function parseURL(url) {
        var location = document.createElement("a");
        location.href = url;
        // IE doesn't populate all link properties when setting .href with a relative URL,
        // however .href will return an absolute URL which then can be used on itself
        // to populate these additional fields.
        if (location.host == "") {
            location.href = location.href;
        }
        return location;
    }

    // PopState
    // ========
    // Bind popstate to our goTo function
    $(window).on("popstate", function (e) {
        console.log('popping state', e.originalEvent.state);
        // Ignore the initial popstate
        //if ( e.originalEvent.state !== null ) {
        //    console.log( 'going to' );
        goTo(location.href, true);
        //}
    });

    // Navigation Items
    // ================

    var $navItems = $('.navigation-menu li').toArray().map($);

    function updateNavItemsActiveState(pathname) {
        $navItems.forEach(function ($el) {
            var linksParsedURL = parseURL($el.find('a').attr('href'));
            if (pathname === linksParsedURL.pathname) {
                $el.addClass('active');
            } else {
                $el.removeClass('active');
            }
        });
    }

    // Loading
    // =======

    function load(url) {

        setIsLoading(true);

        $.get(url).done(function (responseBody) {
            // Set the title and track the page
            document.title = decodeHTML(responseBody.match(/<title>(.*?)<\/title>/)[1].trim());
            _paq.push(['setDocumentTitle', document.title]);
            _paq.push(['setCustomUrl', window.location.href]);
            _paq.push(['trackPageView', document.title]);
            var $incoming = $('[data-clearface-info]', responseBody);
            bindLinks($incoming.children());
            addPageContent($incoming);
        }).fail(function () {
            console.log(arguments);
            console.log('failed to load');
        }).always(function () {
            setIsLoading(false);
        });
    }

    // Loading State
    // =============
    function setIsLoading(yN) {
        if (yN) {
            $('body').addClass('ajax-loading');
        } else {
            $('body').removeClass('ajax-loading');
        }
    }

    // Page Content
    // ============

    function addPageContent($clearfaceInfo) {
        var data = $clearfaceInfo.data();

        if (data.type === 'popover' && $(window).width() > mobileBreakPixels) {
            addPageContentPopOver($clearfaceInfo.children(), '/' + themeRoot + data.popoverBack);
        } else if (data.type === 'main') {
            addPageContentMain($clearfaceInfo.children());
        } else {
            addPageContentMain($clearfaceInfo.children());
        }
    }

    function addPageContentPopOver($content, backingUrl) {
        var $popoverContainer = $('.popover-container');
        var $popover = $('.popover');
        if ($popoverContainer.length === 0) {
            // Add the body class to stop scrolling
            $('body').addClass('popover-enabled');
            $popoverContainer = $("<div class='popover-container'></div>");
            $popover = $("<div class='popover'></div>");
            $popoverContainer.append($popover);
            $('body').append($popoverContainer);
        }
        $popoverContainer.on('click', function (e) {
            if ($(e.target).hasClass('popover-container')) {
                goTo(backingUrl);
            }
        });
        $popover.empty();
        $popover.append($content);
        $popoverContainer.scrollTop(0);
    }

    function addPageContentMain($content, keepPopover) {
        var $main = $('.main');
        if (!keepPopover) {
            removePopover();
        }
        if ($main.length === 0) {
            $main = $('<div class="main"></div>');
            $('.main-container').append($main);
        }
        $main.empty();
        $main.append($content);
    }

    function removePopover() {
        $('body').removeClass('popover-enabled');
        $('.popover-container').remove();
    }
});

// Utils
// =====

function subtractString(string, fragmentToRemove) {
    var stringStart = string.substring(0, fragmentToRemove.length);
    if (fragmentToRemove == stringStart) {
        return string.substring(fragmentToRemove.length);
    }
    return string;
}

function decodeHTML(str) {
    return $("<div>", { html: "" + str }).html();
}